.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
}

.login-wrapper button {
  margin-bottom: 21px;
}

.login-wrapper img {
  margin-bottom: 5px;
}

.input-wrapper label {
  display: flex;
  flex-direction: column;
}

.input-wrapper input {
  margin-bottom: 5px;
}

.main {
  display: flex;
  flex-direction: column;
}
